import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader-indicator',
  templateUrl: './loading-indicator.component.html',
  standalone: true,
  styleUrls: ['./loading-indicator.component.scss'],
})
export class LoaderIndicatorComponent {
  @Input() loading: boolean = false;

  constructor() {}
}
