import { NgClass } from '@angular/common';
import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'app-grid-overlay',
  templateUrl: './grid-overlay.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  styleUrls: ['./grid-overlay.component.scss'],
  imports: [NgClass],
})
export class GridOverlayComponent {
  gridColumns = [...Array(12).keys()];

  //! warning gridBreakpoints has to be in order smallest to biggest
  // keys of scss $grid-breakpoints
  gridBreakpoints = ['xs', 'xsm', 'sm', 'md', 'lg', 'xl', 'xxl'];

  constructor() {}

  getBpClass(bp: string, i: number) {
    if (i === 0) {
      return `d-${this.gridBreakpoints[i + 1]}-none`;
    }
    if (i === this.gridBreakpoints.length - 1) {
      return `d-none d-${bp}-block`;
    }
    return `d-none d-${bp}-block d-${this.gridBreakpoints[i + 1]}-none`;
  }
}
